import React, { useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { initializeParse } from '@parse/react-ssr'
import Script from 'next/script'

import type { AppProps } from 'next/app'

import { store } from '../redux/store'

import Layout from '../components/layout'

import de from '../locales/de.json'
import en from '../locales/en.json'

import '../styles/globals.css'

initializeParse(
  process.env.NEXT_PUBLIC_PARSE_BACKEND!,
  process.env.NEXT_PUBLIC_PARSE_APP_ID!,
  process.env.NEXT_PUBLIC_PARSE_JAVASCRIPT_KEY!
)

const gaId = process.env.NEXT_PUBLIC_GA_ID

const messages: any = { de, en }

function Jobino({ Component, pageProps }: AppProps) {
  const locale = useRouter().locale || 'de'

  return (
    <>
      {/* HERE */}

      {/* Cookie Consent by https://www.FreePrivacyPolicy.com */}
      {/* <Script
        // strategy="lazyOnload"
        type="text/javascript"
        id="cookieconsent"
        src="//www.freeprivacypolicy.com/public/cookie-consent/4.0.0/cookie-consent.js"
        onLoad={() => {
          // TODO: fix this
          ;(window as { [key: string]: any })['cookieconsent']?.run({
            notice_banner_type: 'simple',
            consent_type: 'express',
            palette: 'light',
            language: 'de',
            page_load_consent_levels: ['strictly-necessary'],
            notice_banner_reject_button_hide: false,
            preferences_center_close_button_hide: false,
            website_name: 'Jobino.de',
            website_privacy_policy_url:
              '/nebenjob/agb-und-datenschutz/',
          })
        }}
      ></Script> */}

      {/* End Cookie Consent */}

      {/* <Script
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            // Define dataLayer and the gtag function.
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
      
            // Default ad_storage to 'denied' as a placeholder
            // Determine actual values based on your own requirements
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });
          `
        }}
      /> */}

      {/* <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
      />
      <Script
        id="ga"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaId}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      /> */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gaId}');
      `}
      </Script>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </IntlProvider>
      </Provider>
    </>
  )
}

export default Jobino
