import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import Company from '../../models/Company'

const initialState: Company = {
  id: '',
  attributes: {
    name: '',
    description: '',
    contact: {
      attributes: {
        email: '',
        title: '',
        firstName: '',
        lastName: '',
        street: '',
        additional: '',
        postcode: '',
        city: '',
      },
    },
  },
}

export const editProfileSlice = createSlice({
  name: 'editProfile',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<any>) => {
      state.id = action.payload
    },
    setName: (state, action: PayloadAction<any>) => {
      state.attributes.name = action.payload
    },
    setDescription: (state, action: PayloadAction<any>) => {
      state.attributes.description = action.payload
    },
    setEmail: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.email = action.payload
    },
    setTitle: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.title = action.payload
    },
    setFirstName: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.firstName = action.payload
    },
    setLastName: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.lastName = action.payload
    },
    setStreet: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.street = action.payload
    },
    setAdditional: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.additional = action.payload
    },
    setPostcode: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.postcode = action.payload
    },
    setCity: (state, action: PayloadAction<any>) => {
      state.attributes.contact.attributes.city = action.payload
    },
  },
})

export const {
  setId,
  setName,
  setDescription,
  setEmail,
  setTitle,
  setFirstName,
  setLastName,
  setStreet,
  setAdditional,
  setPostcode,
  setCity,
} = editProfileSlice.actions

export default editProfileSlice.reducer
