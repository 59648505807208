import { useState } from 'react'
import Head from 'next/head'
import Header from './header'
import Footer from './footer'

import useFormatMessage from '../hooks/useFormatMessage'
import CookieConsent from './cookieConsent'
import CookieConfig from './cookieConfig'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const t = useFormatMessage()

  const [accepted, setAccepted] = useState(true)

  const [isConfigOpen, setIsConfigOpen] = useState(false)

  const showCookieBanner = () => setAccepted(false)
  const hideCookieBanner = () => setAccepted(true)

  const showConfig = () => setIsConfigOpen(true)
  const hideConfig = () => setIsConfigOpen(false)

  const gaId = process.env.NEXT_PUBLIC_GA_ID

  return (
    <>
      <Head>
        <title>{t('meta_title')}</title>
        <meta key="description" name="description" content={t('meta_description')} />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="apple-mobile-web-app-title" content="Jobino.de" />
        <meta name="application-name" content="Jobino.de" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />

        <meta property="og:locale" content="de_DE" />
          <meta
            key="ogimage"
            property="og:image"
            content="https://www.jobino.de/nebenjob/wp-content/uploads/2023/05/Gruppenbanner-Neu-jpg.webp"
          />
          <meta
            key="ogimagesecure"
            property="og:image:secure_url"
            content="https://www.jobino.de/nebenjob/wp-content/uploads/2023/05/Gruppenbanner-Neu-jpg.webp"
          />
          <meta
            property="article:author"
            content="https://www.facebook.com/jobino.de"
          />
          <meta key="ogurl" property="og:url" content="https://www.jobino.de/" />
          <meta
            key="ogdescription"
            property="og:description"
            content={t('meta_description')}
          />
          <meta
            key="ogtitle"
            property="og:title"
            content={t('meta_title')}
          />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@jobinoDE" />
          <meta
            key="twittertitle"
            name="twitter:title"
            content={t('meta_title')}
          />
          <meta
            key="twitterdescription"
            name="twitter:description"
            content={t('meta_description')}
          />
          <meta
            key="twitterimage"
            name="twitter:image"
            content="https://www.jobino.de/nebenjob/wp-content/uploads/2023/05/Gruppenbanner-Neu-jpg.webp"
          />

        {/* for google mark ups */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html:
          `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Jobino.de",
            "legalName" : "Jobino GmbH",
            "url": "https://www.jobino.de",
            "logo": "https://www.jobino.de/images/logo.jpg",
            "foundingDate": "2014",

            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Arbeitgeber-Hotline",
              "telephone": "[030 2218 0820]",
              "email": "info@jobino.de"
            },
            "sameAs": [
              "https://www.facebook.com/jobino.de",
              "https://www.xing.com/companies/jobinogmbh-jobino.de",
              "https://www.linkedin.com/company/jobino"
            ]
          }`
        }}>
        </script>
      </Head>
      <Header />

      {/* Google Tag Manager (noscript) */}
      <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${gaId}`}
      height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
      {/* End Google Tag Manager (noscript) */}
      
      <main>{children}</main>
      <Footer />
      <CookieConsent accepted={accepted} showCookieBanner={showCookieBanner} hideCookieBanner={hideCookieBanner} showConfig={showConfig} />
      <CookieConfig show={isConfigOpen} hideCookieBanner={hideCookieBanner} hideConfig={hideConfig} />
    </>
  )
}

export default Layout
