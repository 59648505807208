import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type FilterData from '../../models/FilterData'

const initialState: FilterData = {
  address: '10178 Berlin, Deutschland',
  location: {
    lat: 52.521749,
    lng: 13.397671,
  },
  radius: 50,
  hoursPerWeek: [1, 48],
  hourlyRate: 0,
  jobTypes: [
    'parttimejob',
    'minijob',
    'temporaryjob',
    'weekendjob',
    'internship',
    'studentjob',
  ],
  jobCategories: [
    'fastfood',
    'restaurant',
    'bar',
    'cafe',
    'fashion',
    'groceries',
    'logistik',
    'others',
    'service',
  ],
}

export const filterDataSlice = createSlice({
  name: 'filterData',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload
    },
    setLocation: (
      state,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      state.location = action.payload
    },
    setRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload
    },
    setHoursPerWeekMin: (state, action: PayloadAction<number>) => {
      if (action.payload >= state.hoursPerWeek[1] - 1) {
        state.hoursPerWeek[1] = Number(action.payload) + 1
      }

      state.hoursPerWeek[0] = action.payload
    },
    setHoursPerWeekMax: (state, action: PayloadAction<number>) => {
      if (action.payload <= state.hoursPerWeek[0] + 1) {
        state.hoursPerWeek[0] = Number(action.payload) - 1
      }

      state.hoursPerWeek[1] = action.payload
    },
    setHourlyRate: (state, action: PayloadAction<number>) => {
      state.hourlyRate = action.payload
    },

    setJobTypes: (state, action: PayloadAction<Array<string>>) => {
      state.jobTypes = action.payload
    },
    setJobCategories: (state, action: PayloadAction<Array<string>>) => {
      state.jobCategories = action.payload
    },
  },
})

export const {
  setAddress,
  setLocation,
  setRadius,
  setHoursPerWeekMin,
  setHoursPerWeekMax,
  setHourlyRate,
  setJobTypes,
  setJobCategories,
} = filterDataSlice.actions

export default filterDataSlice.reducer
