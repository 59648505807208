import React, { useState } from 'react'

import useFormatMessage from '../../hooks/useFormatMessage'

import styles from './cookieConfig.module.css'

const defaultMenu = [
  {
    level: null,
    value: null
  }, {
    level: 'strictly-necessary',
    value: true
  }, {
    level: 'functionality',
    value: false
  }, {
    level: 'tracking',
    value: false
  }, {
    level: 'targeting',
    value: false
  }, {
    level: null,
    value: null
  }
]

const defaultToken = 'o5Jv3JvawJZJ'

interface Props {
  show: Boolean,
  hideCookieBanner: any,
  hideConfig: any
}

const CookieConfig: React.FC<Props> = ({ show, hideCookieBanner, hideConfig }) => {

  const t = useFormatMessage()

  const [selected, setSelected] = useState(0)

  const [menu, setMenu] = useState(defaultMenu)

  const setLevel = (level: string, value: boolean) => {
    if(level !== 'strictly-necessary')
      setMenu(menu => {
        const index = menu.findIndex(item => item.level === level)
        menu[index].value = value
        return [...menu]
      })
  }

  const confirm = () => {
    const consentLevel = menu.filter(item => !!item.level).reduce((a, b) => ({ ...a, [b.level!]: b.value }), { })
    const token = defaultToken

    document.cookie = `cookie_consent_user_accepted=true; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_level=${encodeURIComponent(JSON.stringify(consentLevel))}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_user_consent_token=${token}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 400}`

    hideCookieBanner()
    hideConfig()
  }

  if(!show)
    return null

  return (
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <div className={styles.website}>Jobino.de</div>
            <div className={styles.title}>{t('cookie_config_title')}</div>
          </div>
          <button className={styles.close} onClick={hideConfig}>✕</button>
        </div>
        <div className={styles.body}>
          <div className={styles.menu}>
            {Array.from({ length: 6 }).map((_, index) => (
              <button key={index} style={index === selected ? { color: '#111111', backgroundColor: '#FBFBFB' } : { }} className={styles.item} onClick={() => setSelected(index)}>{t(`cookie_config_item_${index}`)}</button>
            ))}
          </div>
          <div className={styles.content}>
            {menu.map((item, index) => (
              <div key={index} style={{ display: selected === index ? 'block' : 'none' }}>
                <div className={styles.title}>{t(`cookie_config_item_title_${index}`)}</div>
                <div className={styles.description}>{t(`cookie_config_item_description_${index}`)}</div>
                {item.level && <div className={styles.toggle}>
                  <div className={item.value ? styles.switchOn : styles.switchOff} onClick={() => setLevel(item.level, !item.value)}>

                  </div>
                  <div className={styles.label}>{item.level === 'strictly-necessary' ? t('cookie_config_always_active') : (item.value ? t('cookie_config_active') : t('cookie_config_inactive'))}</div>
                </div>}
              </div>
            ))}

          </div>
        </div>
        <div className={styles.footer}>
          <button className={styles.confirm} onClick={confirm}>{t('cookie_config_confirm')}</button>
        </div>
      </div>
    </div>
  )
}

export default CookieConfig
  