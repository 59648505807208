const Logo = () => (
  <svg
    width="120"
    height="32"
    viewBox="0 0 120 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3077 13.7937C30.7571 13.7937 32.1188 14.8108 32.0379 16.3026H34.0266C34.0266 14.9238 33.0828 12.1663 29.3077 12.1663V13.7937Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.261 17.2699C19.261 11.4286 23.3141 7.47394 29.2323 7.47394C35.1146 7.47394 39.1677 11.4286 39.1677 17.2699C39.1677 23.0749 35.1146 27.0658 29.2323 27.0658C26.6775 27.0658 24.4703 26.3221 22.7867 25.0098L21.8922 26.6771L18.9598 23.7275C18.9598 23.6595 19.7574 23.2472 20.7524 22.7604C19.7928 21.2372 19.261 19.3725 19.261 17.2699ZM35.0429 17.3061C35.0429 13.6055 32.6397 11.0658 29.2323 11.0658C25.789 11.0658 23.3858 13.6055 23.3858 17.3061C23.3858 21.0431 25.789 23.5828 29.2323 23.5828C32.6397 23.5828 35.0429 21.0431 35.0429 17.3061Z"
      fill="#353535"
    />
    <path
      d="M18.2856 24.4395L13.7015 30.4405L15.2183 32L21.2181 27.3891L18.2856 24.4395Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.8357 10.9569C49.2345 8.70749 51.5301 7.47393 54.5071 7.47393C59.9231 7.47393 63.6175 11.4649 63.6175 17.3424C63.6175 23.1111 60.0307 27.0658 54.5788 27.0658C51.5301 27.0658 49.2345 25.8322 47.8357 23.5828V26.9207H43.7109V0H47.8357V10.9569ZM47.8357 17.1973C47.8357 20.9343 50.2388 23.5102 53.6463 23.5102C57.0537 23.5102 59.4568 20.898 59.4568 17.1973C59.4568 13.6055 57.0537 10.9569 53.6463 10.9569C50.2388 10.9569 47.8357 13.5692 47.8357 17.1973Z"
      fill="white"
    />
    <path d="M71.9994 7.61905V26.9207H67.9104V7.61905H71.9994Z" fill="white" />
    <path
      d="M82.2281 11.3197C83.5911 8.63491 86.0301 7.47393 89.2582 7.47393C93.7058 7.47393 96.36 10.3039 96.36 14.9116V26.9207H92.2352V16.0726C92.2352 13.1701 90.5136 11.3923 87.6442 11.3923C84.4878 11.4286 82.5151 13.6417 82.2281 16.6531V26.9207H78.1033V7.61905H82.2281V11.3197Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.093 17.2699C100.093 11.4286 104.146 7.47393 110.065 7.47393C115.947 7.47393 120 11.4286 120 17.2699C120 23.0749 115.947 27.0658 110.065 27.0658C104.146 27.0658 100.093 23.0749 100.093 17.2699ZM115.875 17.3061C115.875 13.6055 113.472 11.0658 110.065 11.0658C106.621 11.0658 104.218 13.6055 104.218 17.3061C104.218 21.0431 106.621 23.5828 110.065 23.5828C113.472 23.5828 115.875 21.0431 115.875 17.3061Z"
      fill="white"
    />
    <path
      d="M10.9397 1.52379V18.9388C10.9397 21.551 9.61256 22.966 7.3529 22.966C5.63125 22.966 3.65853 21.9864 2.0086 20.1724L0 23.4377C1.86514 25.7597 4.37588 27.102 7.38877 27.102C12.2309 27.102 15.2079 24.1995 15.2079 19.0839V1.52379H10.9397Z"
      fill="white"
    />
    <path
      d="M72.0464 0.0725447H67.9814V4.18441H72.0464V0.0725447Z"
      fill="white"
    />
  </svg>
)

export default Logo
