// import t from '../../hooks/useFormatMessage'
import useFormatMessage from '../../hooks/useFormatMessage'

import Search from '../../assets/icons/search'
import Facebook from '../../assets/icons/facebook'

import styles from './footer.module.css'

const Footer = () => {
  const t = useFormatMessage()

  return (
    <footer className={styles.footer}>
      <ul>
        <li>
          <a
            href="/nebenjob/"
            // target="_blank"
            rel="noreferrer"
          >
            <Search /> <b>{t('header_find_employees')}</b>
          </a>
        </li>
        <li>
          <a
            href="/nebenjob/agb-und-datenschutz/"
            // target="_blank"
            rel="noreferrer"
          >
            {t('footer_terms')}
          </a>
        </li>
        <li>
          <a
            href="/nebenjob/impressum/"
            // target="_blank"
            rel="noreferrer"
          >
            {t('footer_imprint')}
          </a>
        </li>
        <li>
          <a
            aria-label="facebook"
            href="https://www.facebook.com/jobino.de"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook />
            {/* <span className={styles.noPhone}>Facebook</span> */}
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
