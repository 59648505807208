import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  showBackButton: false,
}

export const backButton = createSlice({
  name: 'backButton',
  initialState,
  reducers: {
    setShowBackButton: (state, action: PayloadAction<any>) => {
      state.showBackButton = action.payload
    },
  },
})

export const { setShowBackButton } = backButton.actions

export default backButton.reducer
