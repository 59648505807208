import React, {
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
} from 'react'

import useOnClickOutside from '../../hooks/useOnClickOutside'

import styles from './headerDropdown.module.css'

interface NavItem {
  text: string
  href: string
  icon?: React.ReactNode
}

interface Ref {
  close: Function
}

interface Props {
  children?: React.ReactNode
  icon: React.ReactNode
  buttonLabel: string
  buttonClassName?: string
  items?: NavItem[]
}

const Dropdown: React.ForwardRefRenderFunction<Ref, Props> = (
  props,
  dropdownRef
) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const close = useCallback(() => setIsOpen(false), [])

  useOnClickOutside(ref, close)

  useImperativeHandle(dropdownRef, () => ({ close }), [close])

  return (
    <div className={styles.headerDropdown} ref={ref}>
      <button
        className={props.buttonClassName === 'cta' ? styles.cta : ''}
        onClick={toggleDropdown}
      >
        {props.icon} {props.buttonLabel}
      </button>
      {isOpen && (
        <div className={styles.dropdown}>
          <ul>
            {props.children}
            {props.items?.map((item, i) => (
              <li key={item.text}>
                <a
                  onClick={close}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.icon} {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default React.forwardRef(Dropdown)
