import React, { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/store'
import { setShowBackButton } from '../../redux/slices/backButtonSlice'

// import t from '../../hooks/useFormatMessage'
import useFormatMessage from '../../hooks/useFormatMessage'

import Dropdown from '../headerDropdown'

import Logo from '../../assets/logo'
import Binoculars from '../../assets/icons/binoculars'
// import Search from '../../assets/icons/search'
import Info from '../../assets/icons/info'
import Building from '../../assets/icons/building'
// import Login from '../../assets/icons/login'
// import MultiArrow from '../../assets/icons/multiArrow'
import Caret from '../../assets/icons/caret'
import Chevron from '../../assets/icons/chevron'
import User from '../../assets/icons/user'
import List from '../../assets/icons/list'
import Cart from '../../assets/icons/cart'
import Edit from '../../assets/icons/edit'
import Phone from '../../assets/icons/phone'
import Logout from '../../assets/icons/logout'
import En from '../../assets/flags/en'
import De from '../../assets/flags/de'

import styles from './header.module.css'

const Header: React.FC = () => {
  const router = useRouter()
  const [user, setUser] = useState({} as any)
  const [expanded, setExpanded] = useState(false)

  const dropdownRef = useRef<any>(null)

  // const filterData = useSelector((state: RootState) => state.filterData)
  const { showBackButton } = useSelector((state: RootState) => state.backButton)

  const dispatch = useDispatch()

  const t = useFormatMessage()

  const setLocale = (locale: string) => {
    router.push(router.asPath, router.asPath, { locale: locale })
  }

  const logOut = async () => {
    await Parse.User.logOut()
    close()
    router.reload()
  }

  const close = () => {
    setExpanded(false)
    dropdownRef.current?.close()
  }

  useEffect(() => {
    setUser(Parse.User.current())
  }, [user])

  // const goToSearchResults = () => {
  //   router.replace({
  //     pathname: '/',
  //     query: {
  //       radius: filterData.radius,
  //       address: filterData.address,
  //       lat: filterData.location.lat,
  //       lng: filterData.location.lng,
  //       hourlyRate: filterData.hourlyRate,
  //       hoursMax: filterData.hoursPerWeek[1],
  //       hoursMin: filterData.hoursPerWeek[0],
  //       jobTypes: filterData.jobTypes.join(','),
  //       jobCategories: filterData.jobCategories.join(','),
  //     },
  //   })
  // }

  const back = () => {
    dispatch(setShowBackButton(false))
    router.back()
  }

  const jobView = router.pathname
  // console.log(jobView)

  return jobView !==
    '/job/[company]/[title]/[city]/[postcode]/[companyId]/[jobId]' ? (
    <header className={styles.header + (expanded ? ' ' + styles.expanded : '')}>
      <div className={styles.logoSection}>
        <Link href="/">
          <a aria-label="Home">
            <Logo />
          </a>
        </Link>
        <div className={styles.flags}>
          <button id="de" onClick={() => setLocale('de')}>
            <De />
          </button>
          <button id="en" onClick={() => setLocale('en')}>
            <En />
          </button>
        </div>
        <div className={styles.burger}>
          <button
            aria-label="menu"
            type="button"
            id="menu"
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {/* <span className="sr-only">Toggle navigation</span> */}
            <span className={styles.iconBar}></span>
            <span className={styles.iconBar}></span>
            <span className={styles.iconBar}></span>
          </button>
        </div>
      </div>
      <nav>
        {/* <a
          href="/nebenjob/"
          // target="_blank"
          rel="noreferrer"
        >
          <Search /> <b>{t('header_find_employees')}</b>
        </a> */}
        <a
          href="/nebenjob/arbeitgeber-entdecken/"
          // target="_blank"
          rel="noreferrer"
        >
          <Binoculars /> {t('header_discover_employers')}
        </a>
        {!user && (
          <div className={styles.headerDropdownWrapper}>
            <Dropdown
              // ref={dropdownRef}
              icon={<Info />}
              buttonLabel={t('header_counselor')}
              items={[
                {
                  text: t('header_job_tips'),
                  href: '/nebenjob/tipps-zur-jobsuche/',
                  icon: <Caret />,
                },
                {
                  text: t('header_templates'),
                  href: '/nebenjob/lebenslauf-anschreiben-bewerbung-vorlage/',
                  icon: <Caret />,
                },
                {
                  text: t('header_for_students'),
                  href: '/nebenjob/fuer-schueler-studenten/',
                  icon: <Caret />,
                },
              ]}
            />
          </div>
        )}
        {!user && (
          <div className={styles.headerDropdownWrapper}>
            {/* <Dropdown
              ref={dropdownRef}
              icon={<Building />}
              buttonLabel={t('header_for_employer')}
              buttonClassName="cta"
              items={[
                {
                  text: t('header_find_employees'),
                  href: '/nebenjob/',
                  icon: <Search />,
                },
                {
                  text: t('header_affiliate_network'),
                  href: '/nebenjob/partnernetzwerk-multiposting/',
                  icon: <MultiArrow />,
                },
              ]}
            > */}
            {/*  <li>
                <Link href="/login">
                  <a onClick={close}>
                    <Login /> {t('header_login')}
                  </a>
                </Link>
            </li> */}
            {/* </Dropdown> */}
            {/* <div className={styles.headerDropdown} ref={dropdownRef}> */}
            <div className={styles.headerDropdown}>
              <button
                className={styles.cta}
                // onClick={toggleDropdown}
                onClick={() => router.push('/nebenjob/')}
              >
                {<Building />} {t('header_for_employer')}
              </button>
            </div>
          </div>
        )}
        {user && (
          <div className={styles.headerDropdownWrapper}>
            <Dropdown
              ref={dropdownRef}
              icon={<User />}
              buttonLabel={t('header_account')}
            >
              <li>
                <Link
                  href={`/company/${user?.attributes?.company?.id}/dashboard`}
                >
                  <a onClick={close}>
                    <List /> {t('header_manage_ads')}
                  </a>
                </Link>
              </li>
              <li>
                <b>
                  <a
                    onClick={close}
                    href="/nebenjob/produkt/stellenanzeige-premium/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Cart /> {t('header_book')}
                  </a>
                </b>
              </li>
              <li>
                <Link href={`/company/${user?.attributes?.company?.id}/edit`}>
                  <a onClick={close}>
                    <Edit /> {t('header_edit')}
                  </a>
                </Link>
              </li>
              <li>
                {/* <Link href="/nebenjob/" target="_blank" rel="noreferrer"> */}
                <a
                  onClick={close}
                  href="/nebenjob/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Info /> {t('header_info')}
                </a>
                {/* </Link> */}
              </li>
              <li>
                <Link href="tel:03022180820">
                  <a onClick={close}>
                    <Phone /> {t('header_phone')}: 030 2218 0820
                  </a>
                </Link>
              </li>
              <li>
                <button onClick={logOut}>
                  <Logout /> {t('header_logout')}
                </button>
              </li>
            </Dropdown>
          </div>
        )}
      </nav>
    </header>
  ) : (
    <header className={styles.header + (expanded ? ' ' + styles.expanded : '')}>
      <div className={styles.logoSection}>
        <Link href="/">
          <a aria-label="Home">
            <Logo />
          </a>
        </Link>
        {showBackButton && (
          <button className={styles.back} onClick={back}>
            <span className={styles.chevron}>
              <Chevron />
            </span>
            {t('header_back')}
          </button>
        )}
        <div className={styles.flags}>
          <button id="de" onClick={() => setLocale('de')}>
            <De />
          </button>
          <button id="en" onClick={() => setLocale('en')}>
            <En />
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
