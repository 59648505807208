import { configureStore } from '@reduxjs/toolkit'
import filterDataReducer from './slices/filterDataSlice'
import editProfileReducer from './slices/editProfileSlice'
import currentJobReducer from './slices/currentJobSlice'
import backButtonReducer from './slices/backButtonSlice'

export const store = configureStore({
  reducer: {
    filterData: filterDataReducer,
    editProfile: editProfileReducer,
    currentJob: currentJobReducer,
    backButton: backButtonReducer,
  },
  // TODO: refactoring
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['currentJob/setCurrentJob'],
        // Ignore these paths in the state
        ignoredPaths: ['currentJob.currentJob'],
      },
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
