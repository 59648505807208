import { useEffect } from 'react'

import useFormatMessage from '../../hooks/useFormatMessage'

import styles from './cookieConsent.module.css'

const defaultLevel = {
  'strictly-necessary': true,
  'functionality': false,
  'tracking': false,
  'targeting': false 
}

const acceptAllLevel = {
  'strictly-necessary': true,
  'functionality': true,
  'tracking': true,
  'targeting': true 
}

const defaultToken = 'o5Jv3JvawJZJ'

interface Props {
  accepted: boolean,
  showCookieBanner: any,
  hideCookieBanner: any,
  showConfig: any
}

const CookieConsent: React.FC<Props> = ({ accepted, showCookieBanner, hideCookieBanner, showConfig }) => {

  const t = useFormatMessage()

  useEffect(() => {
    const loaded = !!document.cookie.split(';').map(cookie => cookie.trim()).find(cookie => cookie.startsWith('cookie_consent_level'))
    const accepted = document.cookie.split(';').map(cookie => cookie.trim()).find(cookie => cookie.startsWith('cookie_consent_user_accepted'))?.split('=').pop()?.toLocaleLowerCase() === 'true'
    
    if(!loaded) {
      document.cookie = `cookie_consent_level=${encodeURIComponent(JSON.stringify(defaultLevel))}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 60}`
    }

    if(!accepted)
      showCookieBanner()
  }, [])

  
  const acceptAll = () => {
    const consentLevel = acceptAllLevel
    const token = defaultToken

    document.cookie = `cookie_consent_user_accepted=true; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_level=${encodeURIComponent(JSON.stringify(consentLevel))}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_user_consent_token=${token}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 400}`

    hideCookieBanner()
  }

  const rejectAll = () => {
    const consentLevel = defaultLevel
    const token = defaultToken

    document.cookie = `cookie_consent_user_accepted=true; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_level=${encodeURIComponent(JSON.stringify(consentLevel))}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 62}`
    document.cookie = `cookie_consent_user_consent_token=${token}; path=/; SameSite=Strict; Max-Age=${60 * 60 * 24 * 400}`
  
    hideCookieBanner()
  }

  if(accepted)
    return null

  return (
    <div className={styles.cookieConsent}>
      <div className={styles.title}>{t('cookie_consent_title')}</div>
      <div className={styles.content}>{t('cookie_consent_description')}</div>
      <div className={styles.buttons}>
        <button className={styles.acceptAll} onClick={acceptAll}>{t('cookie_consent_accept_all')}</button>
        <button className={styles.rejectAll} onClick={rejectAll}>{t('cookie_consent_reject_all')}</button>
        <button className={styles.configure} onClick={showConfig}>{t('cookie_consent_configure')}</button>
      </div>
    </div>
  )
}

export default CookieConsent
  