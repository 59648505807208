import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  currentJob: null
}

export const currentJob = createSlice({
  name: 'currentJob',
  initialState,
  reducers: {
    setCurrentJob: (state, action: PayloadAction<any>) => {
      state.currentJob = action.payload
    },
  },
})

export const {
  setCurrentJob,
} = currentJob.actions

export default currentJob.reducer
